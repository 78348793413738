import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion} from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartTwo from "../components/ChartTwo";
import ChartBandLight from "../components/ChartBandLight";
import ChartThreeLight from "../components/ChartThreeLight";

export const FAQPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <Helmet>
      <title>Swiftfox | FAQ</title>
    </Helmet>
    <div className="hero-section faq-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Question?<br /><span>We’ve got the answers.</span>
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Our team of experts are here to assist you in finding the very best currency exchange solutions. If you have any questions, we’re here to help, 24 hours a day, seven days a week. 
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-light" data-srcoll-to to="#help-me">
                    I need help
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item hovering">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="hero-chart-light pushmeright chart-perspective">
                  <StockGainerPersonal />
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner-blue">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-ticker">
                <ChartBandLight />
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="callout-section orange-bg white-text" id="help-me">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack">
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="No Hidden Fees" src="../img/fee.svg" />
                      <p>No Hidden Fees</p>
                    </div>


                    <div className="bullet">
                      <img alt="24/7 Support" src="../img/sup.svg" />
                      <p>24/7<br />Support</p>
                    </div>
                    <div className="bullet">
                      <img alt="Account Managers" src="../img/manager.svg" />
                      <p>Dedicated Acc. Managers</p>
                    </div>
                    <div className="bullet">
                      <img alt="Payments" src="../img/fast.svg" />
                      <p>Fast & Easy Int. Payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Beneficiaries" src="../img/saved.svg" />
                      <p>Pre-Saved Beneficiaries</p>
                    </div>
                    <div className="bullet">
                      <img alt="Trade & Status History" src="../img/history.svg" />
                      <p>Trade Status & History</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Are there currencies you can’t exchange?
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  We take our regulatory responsibilities very seriously. In line with our anti-money laundering policy and sanction screening requirements, we cannot receive funds from or send money to the following countries. However, we’re continually looking to expand our capabilities, so this may be subject to change.

                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
   
    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                <span>What kinds of things affect currency value?</span>
              </motion.h2>
            )}
          </InView>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                Key upcoming economic events, announcements and news.
              </motion.p>
            )}
          </InView>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="hero-chart-light sizefixer">
                <ChartThreeLight />
              </motion.div>
            )}
          </InView>
        </div>
      </div>
    </div>

    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items holiday">
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                <span>United States, Americas, Canada: Holiday calendar</span>
              </motion.h2>
            )}
          </InView>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                All holiday times are based on EST, New York
              </motion.p>
            )}
          </InView>
          <div className="faq-grid">
            <div className="faq-item faq-header">
              <p>Date</p>
              <p>Holiday Name</p>
            </div>
            <div className="faq-item">
              <p>Wednesday, 1 January</p>
              <p>New Years Day</p>
            </div>
            <div className="faq-item">
              <p>Friday, 10 April</p>
              <p>	Good Friday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 13 April</p>
              <p>Easter Monday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 8 May</p>
              <p>Early May Bank Holiday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 25 May</p>
              <p>	Spring Bank Holiday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 31 August</p>
              <p>Summer Bank Holiday</p>
            </div>
            <div className="faq-item">
              <p>Friday, 25 December</p>
              <p>	Christmas Day</p>
            </div>
            <div className="faq-item">
              <p>Monday, 28 December</p>
              <p>Boxing Day (substitute day)</p>
            </div>
          </div>
          <br/><br/>
          <br/><br/>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                <span>United Kingdom, Europe, Asia, Africa: Holiday calendar</span>
              </motion.h2>
            )}
          </InView>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                All holiday times are based on GMT, London
              </motion.p>
            )}
          </InView>
          <div className="faq-grid">
            <div className="faq-item faq-header">
              <p>Date</p>
              <p>Holiday Name</p>
            </div>
            <div className="faq-item">
              <p>Wednesday, 1 January</p>
              <p>New Years Day</p>
            </div>
            <div className="faq-item">
              <p>Friday, 10 April</p>
              <p>	Good Friday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 13 April</p>
              <p>Easter Monday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 8 May</p>
              <p>Early May Bank Holiday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 25 May</p>
              <p>	Spring Bank Holiday</p>
            </div>
            <div className="faq-item">
              <p>Monday, 31 August</p>
              <p>Summer Bank Holiday</p>
            </div>
            <div className="faq-item">
              <p>Friday, 25 December</p>
              <p>	Christmas Day</p>
            </div>
            <div className="faq-item">
              <p>Monday, 28 December</p>
              <p>Boxing Day (substitute day)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
        <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                <span>Payment timelines</span>
              </motion.h2>
            )}
          </InView>
          <div className="faq-grid">
            <div className="faq-item faq-header">
              <p>Currency (ISO)</p>
              <p>Priority (SWIFT)</p>
              <p>Cut off times (GMT)</p>
            </div>
            <div className="faq-item faq-header hidden-mobile">
              <p>Currency (ISO)</p>
              <p>Priority (SWIFT)</p>
              <p>Cut off times (GMT)</p>
            </div>
            <div className="faq-item">
              <p>Australian Dollar (AUD)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Bahrain Dinar (BHD)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Bulgarian Lev (BGN)</p>
              <p>0 days</p>
              <p>8:30 am</p>
            </div>
            <div className="faq-item">
              <p>Canadian Dollar (CAD)</p>
              <p>0 days</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Chinese Yuan (CNY)</p>
              <p>1 day</p>
              <p>9:00 am</p>
            </div>
            <div className="faq-item">
              <p>Croatian Kuna (HRK)</p>
              <p>0 days</p>
              <p>8:30 am</p>
            </div>
            <div className="faq-item">
              <p>Czech Koruna (CZK)</p>
              <p>0 days</p>
              <p>9:00 am</p>
            </div>
            <div className="faq-item">
              <p>Danish Krone (DKK)</p>
              <p>0 days</p>
              <p>10:30 am</p>
            </div>
            <div className="faq-item">
              <p>Euro (EUR)</p>
              <p>0 days</p>
              <p>2:30 pm</p>
            </div>
            <div className="faq-item">
              <p>HongKong Dollar (HKD)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Hungarian Forint (HUF)</p>
              <p>0 days</p>
              <p>9:00 am</p>
            </div>
            <div className="faq-item">
              <p>Japanese Yen (JPY)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Kenyan Shilling (KES)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Kuwait Dinar (KWD)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Mexican Peso (MXN)</p>
              <p>0 days</p>
              <p>9:00 am</p>
            </div>
            <div className="faq-item">
              <p>Israeli Shekel (ILS)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>New Zealand Dollar (NZD)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Norwegian Krone (NOK)</p>
              <p>0 days</p>
              <p>10:30 am</p>
            </div>
            <div className="faq-item">
              <p>Omani Rial (OMR)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Polish Zloty (PLN)</p>
              <p>0 days</p>
              <p>9:30 am</p>
            </div>
            <div className="faq-item">
              <p>Qatar Rial (QAR)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Romanian Leu (RON)</p>
              <p>0 days</p>
              <p>9:00 am</p>
            </div>
            <div className="faq-item">
              <p>Saudi Riyal (SAR)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>Singapore Dollar (SGD)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>South African Rand (ZAR)</p>
              <p>0 days</p>
              <p>10:30 am</p>
            </div>
            <div className="faq-item">
              <p>Swedish Krona (SEK)</p>
              <p>0 days</p>
              <p>10:30 am</p>
            </div>
            <div className="faq-item">
              <p>Swiss Franc (CHF)</p>
              <p>0 days</p>
              <p>10:30 am</p>
            </div>
            <div className="faq-item">
              <p>Thai Baht (THB)</p>
              <p>1 day</p>
              <p>9:00 am</p>
            </div>
            <div className="faq-item">
              <p>Turkish Lira (TRY)</p>
              <p>0 days</p>
              <p>9:30 am</p>
            </div>
            <div className="faq-item">
              <p>Ugandan Shilling (UGX)</p>
              <p>1 day</p>
              <p>9:00 am</p>
            </div>
            <div className="faq-item">
              <p>UK Sterling (GBP)</p>
              <p>0 days</p>
              <p>4:30 pm</p>
            </div>
            <div className="faq-item">
              <p>United Arab Emirates Dirham (AED)</p>
              <p>1 day</p>
              <p>2:00pm</p>
            </div>
            <div className="faq-item">
              <p>US Dollar (USD)</p>
              <p>0 days</p>
              <p>4:30 pm</p>
            </div>
          </div>
        
        </div>
      </div>
      
    </div>

    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items sanc">
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                <span>Sanctioned countries</span>
              </motion.h2>
            )}
          </InView>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                We take our regulatory responsibilities very seriously. In line with our anti-money laundering policy and sanction screening requirements, we cannot receive funds from or send money to the following countries, although this may be subject to change.
              </motion.p>
            )}
          </InView>
          <div className="faq-grid">
            <div className="faq-item">
              <p>Crimea</p>
            </div>
            <div className="faq-item">
              <p>Libya</p>
            </div>
            <div className="faq-item">
              <p>Sudan</p>
            </div>
            <div className="faq-item">
              <p>Cuba</p>
            </div>
            <div className="faq-item">
              <p>North Korea</p>
            </div>
            <div className="faq-item">
              <p>Syria</p>
            </div>
            <div className="faq-item">
              <p>Iran</p>
            </div>
            <div className="faq-item">
              <p>South Sudan</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items glossary">
        <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                <span>Glossary</span>
              </motion.h2>
            )}
          </InView>
          <div className="faq-grid">
            <div className="faq-item">
              <h4>Anti-money laundering (AML)</h4>
              <p>Anti-money laundering (AML) refers to a set of procedures, laws and regulations governments and financial regulation authorities have designed to stop the practice of generating income through illegal actions.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Automated Clearing House (ACH)</h4>
              <p>A US-based organisation that processes massive amounts of debit and credit transactions such as direct deposit, payroll and vendor USD payments in the USA.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Bid Rate</h4>
              <p>The bid rate is the rate quoted to you when you’re about to buy a currency.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Correspondent bank</h4>
              <p>Banks are financial institutions that are licensed to provide financial services, such as deposit taking, lending, currency exchange and payment services. A correspondent bank is a financial institution that provides services on behalf of another financial institution.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Cross-border payment</h4>
              <p>Cross-border payment is a transaction in which a payer in one country transfers money to a payee operating in a different country via the foreign exchange market, using a bank or third party payment processor.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Crosses</h4>
              <p>In foreign exchange, crosses refer to the exchange rate of currencies being traded in a country that does not utilise either of those currencies. For example, a trader in the UK dealing in Mexican Pesos and Euros will trade them at the ‘cross rate’.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Currencies</h4>
              <p>Currencies are a system of money accepted within a country, for example the US Dollar in the US, or the Euro across the Eurozone.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Currency pairs</h4>
              <p>A currency pair is the quotation and pricing structure of the currencies being traded. The value of a currency is a rate and is determined by its comparison to another currency.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Delivery Date</h4>
              <p>The delivery date is the date on which the purchased funds are available for payment.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Exchange rate</h4>
              <p>An exchange rate is the price of one country's currency expressed in another country's currency. An exchange rate has two components, the domestic currency and a foreign currency (see currency pairs), and can be quoted either directly or indirectly.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Faster Payments</h4>
              <p>The UK's banking initiative to reduce payment times between different domestic banks' customer GBP accounts.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Forward Conversion</h4>
              <p>A forward conversion is a conversion with a settlement date greater than two days later - this can be anything from three days to one year hence.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>ISO Currency Code</h4>
              <p>The ISO Currency Code is the three letters also used in exchange rates. Devised by the International Organization for Standardization it is a country’s two characters and a letter denoting the country’s currency. For example, in Hong Kong the currency is Dollars and so the ISO code is HKD.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Know Your Client (KYC)</h4>
              <p>Know Your Client (KYC) is a requirement that protects both financial institutions and their clients. Financial institutions are required to formally verify the identity of all clients and understand the purpose of trading, expected volumes and jurisdictions their clients will use.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Local payment schemes</h4>
              <p>Local payment schemes are payment initiatives set up in a domestic market to make the payment process between banks more efficient. Schemes include ACH, Faster Payments and SEPA. </p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Margin</h4>
              <p>Margin is financial collateral used to cover credit risk and is set aside as a good will deposit as collateral to hold a position against future trades as security to cover any potential losses the provider may incur as a result of non-settlement of the trade.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Margin call</h4>
              <p>A margin call means that the exchange rate has moved sufficiently so that the initial margin held is no longer sufficient to support the “open” or unsettled trades and additional deposit is required to cover the risk to the provider.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Measuring rates</h4>
              <p>Since most currencies are quoted to a maximum of 4 decimal places, movement in currency rates, no matter how small, will have an impact on the price you pay. Rates are measured in three factors. These are spreads, pips and basis points. The spread is the difference between ‘bid’ and ‘offer’ prices.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Mid-market rate</h4>
              <p>The mid-market rate is the average rate between the bid and offer rates agreed upon when conducting a foreign exchange transaction.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Offer rate</h4>
              <p>The offer rate is the rate quoted to you when you’re about to sell currency. Also known as the ask price or sell price.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Payee</h4>
              <p>A payee is an entity receiving payments through any form of money transfer method. Also known as the beneficiary.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Payer</h4>
              <p>A payer is an entity, either an individual or organisation, that makes a payment to another entity.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Payment networks</h4>
              <p>A payment network is an electronic system used to settle financial payments between institutions, individuals or organisations. These are also known as ‘rails’.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Quote rates</h4>
              <p>The quote rate is the most recent market price that a currency was traded on, meaning a buyer and seller agreed to trade at that price.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Regulated entities</h4>
              <p>Regulated entities are the service providers governed by financial regulation, such as banks, brokers and payments companies.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Sanctions</h4>
              <p>Economic sanctions are commercial and financial penalties and restrictions applied by one or more countries against a targeted country, group, or individual. Sanctions may include various forms of trade barriers, tariffs, and restrictions on financial transactions.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Settlement date</h4>
              <p>The settlement date is the day on which a trade or a contract must be reconciled. The buyer must make payment of the sold currency before the conversion is processed and the purchased currency is available for payment.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Single Euro Payments Area (SEPA)</h4>
              <p>The European's domestic clearing scheme for simplifying the European banking transaction network and processing EUR payments within the SEPA region.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Spot Conversion</h4>
              <p>A spot conversion is a conversion that will settle in two days (T+2).</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>SWIFT</h4>
              <p>SWIFT stands for Society for Worldwide Interbank Financial Telecommunications. Set up in 1973, it’s a member-owned cooperative that developed a communication platform to transmit secure financial transaction information between the financial institutions it represents.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Third-Party Payments Processor</h4>
              <p>A third-party payments processor lets businesses handle transactions from various channels such as credit cards and debit cards, without having to establish their own banking relationships or set up a merchant bank account of their own.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>TOD Conversion</h4>
              <p>A TOD conversion is a conversion that will settle today.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>TOM Conversion</h4>
              <p>A TOM conversion is a conversion that will settle tomorrow (T+1). The T stands for Transaction Date.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Wholesale Rate</h4>
              <p>The wholesale market used by banks, governments, investment funds and large corporates to buy and sell foreign currency is called the Spot Market. Spot means “on the spot” prices for soonest delivery possible. For most currencies, the standard settlement period for spot trades is two business days.</p>
            </div>
          </div>

          <div className="faq-grid">
            <div className="faq-item">
              <h4>Wire transfer</h4>
              <p>Wire transfer is the means of transferring money electronically around the world. This can be institutions, individuals or organisations. Also known as a ‘SWIFT payment’ as wires are dependent on SWIFT messaging and correspondent banking.</p>
            </div>
          </div>

        
        </div>
      </div>
      
    </div>



  </div>
);

FAQPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const FAQPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <FAQPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

FAQPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default FAQPage;

export const pageQuery = graphql`
  query FAQPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
